var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"child-tree-list"},[_c('article',{staticClass:"menu-box"},[(_vm.menuList && _vm.menuList.length)?_c('div',{staticClass:"menu-list"},[_c('el-menu',{staticClass:"menu-main",attrs:{"default-active":_vm.defaultActive,"default-openeds":_vm.defaultOpened}},_vm._l((_vm.menuList),function(item){return (
						item.menu_type === 2
					  && !item.is_hide
						&& _vm.judgingArchiAPermission(item.archi_limit, item.permissions)
						&& _vm.judgingCustomLogic(item.custom_logics)
					)?_c('el-submenu',{key:item.id,attrs:{"index":item.menu_path}},[_c('template',{slot:"title"},[(item.icon && item.icon.toString().indexOf('el-icon') === 0)?_c('i',{staticClass:"group-icon",class:item.icon}):_c('i',{staticClass:"group-icon",class:['iconfont', item.icon || 'iconyeqian']}),_c('span',{staticClass:"group-title",attrs:{"title":item?.name?.length >= 8 ? item.name : ''}},[_vm._v(_vm._s(item.name))])]),_vm._l((item.children_list),function(child){return _c('span',{key:child.id},[(!child.is_hide
								&& _vm.judgingArchiAPermission(child.archi_limit, child.permissions)
								&& _vm.judgingCustomLogic(child.custom_logics)
							)?_c('el-menu-item',{class:{
								'is-active': _vm.isCurrentActive(child),
								'without-active': !_vm.isCurrentActive(child)
							},attrs:{"index":child.menu_path || child.link},on:{"click":function($event){return _vm.onItem(child)},"contextmenu":function($event){return _vm.onContextMenu(child, $event)}}},[_c('template',{slot:"title"},[_c('span',{staticClass:"child-span",attrs:{"title":child?.name?.length >= 4 ? child.name : ''}},[_vm._v(_vm._s(child.name))])])],2):_vm._e()],1)})],2):(!item.is_hide
						&& _vm.judgingArchiAPermission(item.archi_limit, item.permissions)
						&& _vm.judgingCustomLogic(item.custom_logics)
					)?_c('el-menu-item',{staticClass:"lone-menu-item",class:{
						'is-active': _vm.isCurrentActive(item),
						'without-active': !_vm.isCurrentActive(item)
					},attrs:{"index":item.menu_path || item.link},on:{"click":function($event){return _vm.onItem(item)},"contextmenu":function($event){return _vm.onContextMenu(item, $event)}}},[(item.icon && item.icon.toString().indexOf('el-icon') === 0)?_c('i',{class:['lone-menu-icon', item.icon]}):_c('i',{class:['lone-menu-icon','iconfont', item.icon || 'iconyeqian']}),_c('span',{staticClass:"lone-menu-title",attrs:{"slot":"title","title":item?.name?.length >= 4 ? item.name : ''},slot:"title"},[_vm._v(_vm._s(item.name))])]):_vm._e()}),1)],1):_vm._e()]),(_vm.systemConfig?.menuImage)?_c('img',{staticClass:"aphorism-img",attrs:{"src":_vm.systemConfig.menuImage,"alt":"menuImage"}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }