<!--
 * @Description: 侧边栏
 * @Author: luocheng
 * @Date: 2022-07-13 15:39:44
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-20 14:24:11
-->
<template>
  <div class="nav-bar side-bar-use" :class="themeType === 4?'no-box-shadow':''">
    <template v-if="themeType === 3">
      <MenuPartThree></MenuPartThree>
      <MenuListThree></MenuListThree>
    </template>
    <template v-else-if="themeType === 4">
      <MenuTreeList />
    </template>
    <!-- 南华、罗铁类型 -->
    <template v-else>
      <MenuPart></MenuPart>
      <MenuList></MenuList>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MenuList from './MenuList';
import MenuTreeList from './MenuTreeList';
import MenuPart from './MenuPart';
import MenuListThree from './MenuListThree';
import MenuPartThree from './MenuPartThree';

export default {
  name: 'NavBar',
  components: {
    MenuList,
    MenuPart,
    MenuListThree,
    MenuPartThree,
    MenuTreeList
  },
  computed: {
    ...mapState(['systemConfig']),
    themeType() {
      return this.systemConfig?.theme_type;
    }
  }
}
</script>

<style lang="less" scoped>
.nav-bar{
  width: auto;
  min-width: 64px;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 0.08); 
}
.no-box-shadow{
  box-shadow: none!important;
}
</style>