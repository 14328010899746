<!--
 * @Description: 菜单列表
 * @Author: luocheng
 * @Date: 2021-10-30 13:52:21
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-06-19 14:40:50
-->
<template>
	<article class="child-tree-list"	>
		<article class="menu-box">
			<div class="menu-list" v-if="menuList && menuList.length">
				<!-- 菜单默认收起@忠哥 -->
				<!-- eslint-disable -->
				<el-menu
					:default-active="defaultActive"
					class="menu-main"

					:default-openeds="defaultOpened"
				>
					<el-submenu
						v-if="
							item.menu_type === 2
						  && !item.is_hide
							&& judgingArchiAPermission(item.archi_limit, item.permissions)
							&& judgingCustomLogic(item.custom_logics)
						"
						:index="item.menu_path"
						v-for="item in menuList"
						:key="item.id"
					>
						<template slot="title">
							<i class="group-icon" :class="item.icon" v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"></i>
							<i v-else class="group-icon" :class="['iconfont', item.icon || 'iconyeqian']"></i>
							<span class="group-title" :title="item?.name?.length >= 8 ? item.name : ''">{{ item.name }}</span>
						</template>
						<span v-for="child in item.children_list" :key="child.id">
							<el-menu-item
								:index="child.menu_path || child.link"
								@click="onItem(child)"
								@contextmenu="onContextMenu(child, $event)"
								v-if="!child.is_hide
									&& judgingArchiAPermission(child.archi_limit, child.permissions)
									&& judgingCustomLogic(child.custom_logics)
								"
								:class="{
									'is-active': isCurrentActive(child),
									'without-active': !isCurrentActive(child)
								}"
							>
								<template slot="title">
									<!-- <i :class="['item-icon', child.icon]" v-if="child.icon && child.icon.toString().indexOf('el-icon') === 0"></i>
									<i v-else :class="['item-icon', 'iconfont', child.icon || 'iconyeqian']"></i> -->
									<span class="child-span" :title="child?.name?.length >= 4 ? child.name : ''">{{ child.name }}</span>
								</template>
							</el-menu-item>
						</span>
					</el-submenu>
					<!-- 不带下级 -->
					<el-menu-item
						v-else-if="!item.is_hide
							&& judgingArchiAPermission(item.archi_limit, item.permissions)
							&& judgingCustomLogic(item.custom_logics)
						"
						class="lone-menu-item"
						:index="item.menu_path || item.link"
						@click="onItem(item)"
						@contextmenu="onContextMenu(item, $event)"
						:class="{
							'is-active': isCurrentActive(item),
							'without-active': !isCurrentActive(item)
						}"
					>
						<i :class="['lone-menu-icon', item.icon]" v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"></i>
						<i :class="['lone-menu-icon','iconfont', item.icon || 'iconyeqian']" v-else></i>
						<span class="lone-menu-title" slot="title" :title="item?.name?.length >= 4 ? item.name : ''">{{ item.name }}</span>
					</el-menu-item>
				</el-menu>
			</div>
		</article>
		<img v-if="systemConfig?.menuImage" class="aphorism-img" :src="systemConfig.menuImage" alt="menuImage">
	</article>
</template>

<script>
import partMixin from './partMixin';
import { Menu, MenuItem, Submenu } from 'element-ui';
import { openUrl } from '@/utils/tools';
  

export default {
	name: 'MenuList',
	mixins: [partMixin],
	components: {
    'el-menu': Menu,
    'el-submenu': Submenu,
    'el-menu-item': MenuItem,
  },
	computed: {
    // 默认展开的数组
    defaultOpened() {
      if (!this.menuList || !this.menuList.length) return [];
      const result = [];
      const path = this.$route.path;
      for (let i = 0; i < this.menuList.length; i++) {
        const item = this.menuList[i];
        const url = item?.menu_path || item?.link;
        if (url && this.isChildPath(url, path)) {
          result.push(item?.menu_path || item?.link);
        } else {
          const children = item?.children_list || [];
          if (children && children.length && children.find(ele => this.isChildPath(ele?.menu_path || ele?.link, path))) {
            result.push(item?.menu_path || item?.link);
          }
        }
      }
      return result;
    },
     // 默认选中
    defaultActive() {
      if (!this.menuList || !this.menuList.length) return '';
      const path = this.$route.path;
			return path
    },
	},
	methods:{
		isChildPath(url, path) {
      if (!url || typeof url !== 'string') return false;
      if (url === path) return true;
      const arr = url.split('/');
      return arr.includes(path);
    },
		/**
     * @desc: 点击菜单
     * @param {Object} menu 菜单数据
     */
    async onItem(menuData) {
      this.currentPart = menuData;
      this.$store.commit('setCurrentMenu', {
        level: 4,
        menuData
      });
      // 功能组展开下级
      const { menu_type, link, link_type, menu_path, page_uuid } = menuData;
      // 进入应用历史记录
      if ([0, 1, 3, 4].includes(menu_type)) {
        this.$store.commit('setAppNavHistory', {
          origin: 11,
          history: menuData
        });
      }
      if (menu_type === 0) {
        // 页面
        if (this.$route.path === menu_path) return;
        this.$router.push({
          path: menu_path,
          query: {
            pageUUID: page_uuid
          }
        });
      } else if (menu_type === 1) {
        // 跳转链接
        let linkUrl = link;
        if (link === 'DATAVIEW') {
          // 大屏
          linkUrl = await this.getDataViewUrl();
        }
        if (!linkUrl) {
          this.$message.error('出错了，请重试!');
          return false;
        }
        if (link_type === '_blank') {
          openUrl(linkUrl,linkUrl)
        } else if (link_type === '_self') {
          window.location.href = linkUrl;
        } else {
          openUrl(linkUrl,linkUrl)
        }
      } else if (menu_type === 3) {
        // 暂时弃用
        if (this.$route.path === menu_path) return;
        // 微前端页面
        this.$router.push({
          path: menu_path,
          query: {
            type: 'micro'
          }
        });
      } else if (menu_type === 4) {
        if (this.$route.path === menu_path) return;
        this.$router.push({
          path: menu_path
        });
      }
    },
	}
}
</script>

<style lang="less" scoped>
@itemHeight: 32px;
.child-tree-list{
	width: 198px;
	box-sizing: border-box;
	display: flex;
	padding: 20px 20px 0 20px;
	flex-direction: column;
	transition: all 0.2s;
	user-select: none;
	position: relative;
  .aphorism-img{
		width: 100%;
	}
  .menu-box {
		flex:1;
		width: 100%;
		overflow-y: auto;
    .menu-list{
      height: 100%;
      width: 100%;
      :deep(.el-menu) {
        width: 100%;
        border: none!important;
        .el-menu-item {
          height: @itemHeight;
          line-height: @itemHeight;
          text-align: left;
					width: calc(100% - 40px);
          margin-bottom: 12px;
					padding:0 8px!important;
          display: flex;
					overflow: hidden;
					color: var(--Txt-2, #7A8599);
					text-overflow: ellipsis;
					white-space: nowrap;
					font-family: "PingFang SC";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					letter-spacing: 0.5px;
          .item-icon{
            line-height: @itemHeight;
						color:  #7A8599;
						font-size: 20px;
            margin-right: 12px;
						margin-left: 20px;
          }
					.child-span{
						margin-left: 32px;
						width: calc(100% - 66px);
						border-radius: 8px;
						overflow: hidden;
					}
        }
        .el-submenu__title {
          height: @itemHeight;
          line-height: @itemHeight;
          text-align: left;
          margin-bottom: 12px;
					display: flex;
					flex-direction: row;
					.group-icon {
            color:  #7A8599;
						font-size: 20px;
						margin-right: 12px;
						line-height: 32px;
          }
          .group-title{
						flex: 1;
            line-height: @itemHeight;
						overflow: hidden;
						display: inline-block;
						text-overflow: ellipsis;
						white-space: nowrap;
          }
        }
				.el-submenu__title:hover{
					background: none!important;
					.group-icon {
            color:  #E46445!important;
          }
					.group-title{
						color: #7A8599!important;
          }
				}
      }
			.lone-menu-item{
				width: 100%!important;
				.lone-menu-icon{
					line-height: @itemHeight;
					color:  #7A8599;
					font-size: 20px;
					margin-right: 12px;
				}
				.lone-menu-title{
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			.lone-menu-item:hover{
				background: none!important;
				.lone-menu-icon {
					color:  #E46445!important;
				}
				.lone-menu-title{
					color: #7A8599!important;
				}
			}
    }
  }
}
</style>
<style>
.child-tree-list  .el-menu-item.is-active{
	border-radius: 8px;
	background: #FEE9E4!important;
	color: var(--Color-01, #E46445)!important;
}
.child-tree-list  .el-menu-item.is-active i{
	color: var(--Color-01, #E46445)!important;
}
.child-tree-list .el-menu{
	background: transparent!important;
	color: #7A8599!important;
}
.child-tree-list .iconfont {
	font-size: 20px;
}
.child-tree-list .el-submenu__title{
	padding: 0!important;
	color:  #7A8599;
	padding: 0 8px!important;
	font-family: "PingFang SC";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.5px;
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.child-tree-list .el-menu.el-menu--inline span{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.child-tree-list .el-submenu__icon-arrow{
	right: 0!important;
	font-size: 16px;
}
.child-tree-list .el-submenu__icon-arrow:before{
	content:"\e790"!important;
}

.child-tree-list .el-submenu__title:hover,.child-tree-list .el-menu-item:focus, .child-tree-list .el-menu-item:hover {
		border-radius: 8px;
    outline: 0;
    background: #FEE9E4!important;
}
.child-tree-list .menu-box::-webkit-scrollbar {
  display: none;
}
</style>