var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.systemConfig)?_c('div',{staticClass:"theme-three",style:(_vm.bgObj)},[_c('section',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.systemConfig.little_logo || _vm.systemConfig.logo,"alt":_vm.systemConfig.name}}),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.systemConfig.sortName || _vm.systemConfig.name))])]),(!_vm.isUpdatePwd)?[_c('section',{staticClass:"fix-actions"},[(_vm.systemConfig.need_second)?_c('a',{staticClass:"back-second",attrs:{"href":"javascript:;"},on:{"click":_vm.onHome}},[_c('img',{attrs:{"src":require("@/assets/images/back-home.png"),"alt":"首页"}})]):_vm._e(),(_vm.systemConfig.need_app_center)?_c('a',{staticClass:"app-center",class:{
              'active': _vm.showapps
            },attrs:{"href":"javascript:;"},on:{"click":_vm.onAPPCenter}},[_c('i',{staticClass:"iconfont iconc-myapp"})]):_vm._e()]),_c('ul',{staticClass:"nav-list"},[(!_vm.isUpdatePwd)?_vm._l((_vm.topNav),function(item){return (!item.is_hide
							&& _vm.judgingArchiAPermission(item.archi_limit, item.permissions, true)
							&& _vm.judgingCustomLogic(item.custom_logics)
						)?_c('li',{key:item.key,staticClass:"nav-item",class:{
							'active': _vm.currentMenu && ( +_vm.currentMenu.id === +item.id || _vm.currentMenu.menu_path === item.menu_path) && !_vm.showapps
						},on:{"click":function($event){return _vm.onTopNav(2, item, true)}}},[(item.icon)?[(item.icon.indexOf('el-icon') === 0)?_c('i',{class:['element-iconfont', item.icon]}):_c('i',{class:['iconfont', item.icon]})]:_vm._e(),_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.name))])],2):_vm._e()}):_vm._e()],2),_c('section',{staticClass:"additional-content"},[(_vm.needFiveParties)?_c('Cooperation'):_vm._e(),(_vm.needArchiInfo)?_c('SelectArchi',{attrs:{"reload":true,"archiDescBox":{
          padding: '0px 10px'
        }}}):_vm._e(),(_vm.needAppQrcode)?_c('appQrcode'):_vm._e(),_c('UserActions',{attrs:{"infoDescBoxStyle":{
						padding: '9px 10px'
					},"avatarStyle":{
						height: '32px',
						width: '32px'
					},"nameStyle":{
						lineHeight: '24px'
					}}})],1)]:_vm._e(),_c('Apphome',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAppCenter),expression:"showAppCenter"}],on:{"close":_vm.onCloseAppHome}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }